import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import styled from "styled-components";

import { ButtonLinkIcon } from "@components/Button";
import Card from "@components/Card";
import Chip from "@components/Chip";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { Text, TextMediumWeight, TextSemiBoldWeight } from "@components/Text";

import JsonFormDialog from "./JsonFormDialog";

import { FORM_INPUT_TYPE } from "@utils/enum";
import { formatDateAndTime, isAdminUser } from "@utils/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  compose,
  space,
  layout,
  flexbox,
  color,
  typography,
  border,
  padding,
} from "styled-system";

TextMediumWeight.defaultProps = {
  fontSize: "var(--fs-text-m)",
};

Text.defaultProps = {
  fontSize: "var(--fs-text-m)",
};

const StyledDataTable = styled(DataTable)`
  ${compose(layout, space, flexbox, color, typography, border, padding)};
  .p-datatable-thead th {
    background-color: transparent !important;
    padding: 8px !important;
  }
`;

const StyledDescription = styled.div`
  font-weight: var(--light-weight);
  color: var(--grey-dark);
  line-height: var(--lh-text-m);

  strong {
    font-weight: var(--semibold-weight);
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;

    li {
      margin-top: 8px;
      margin-left: 24px;
    }
  }
`;

const renderFiller = ({ name, index }) => (
  <Div
    key={`${name}_${index}`}
    flex="1 1 40%"
    display={["none", "none", "block", "block"]}
  />
);

const renderText = ({ name, index, label, value }) => (
  <Div key={`${name}_${index}`} flex="1 1 40%">
    <TextMediumWeight>
      {label}
      {`: `}
      <Text>{value}</Text>
    </TextMediumWeight>
  </Div>
);

const renderCalendar = ({ name, index, label, value }) => (
  <Div key={`${name}_${index}`} flex="1 1 40%">
    <TextMediumWeight>
      {label}
      {`: `}
      <Text>{formatDateAndTime(value)}</Text>
    </TextMediumWeight>
  </Div>
);

const renderDropdown = ({
  label,
  value,
  options,
  optionFields,
  selectorField,
}) => {
  const selectedOption = options.find(option => option.value === value);
  const presentedValue = selectedOption?.[selectorField];
  const presentedLabel = selectedOption?.label || "";

  return (
    <>
      <Div flex="1 1 40%">
        <TextMediumWeight>
          {label}
          {`: `}
          <Text>{presentedValue || presentedLabel}</Text>
        </TextMediumWeight>
      </Div>

      {optionFields?.[value]?.map(
        ({ name, value, label, fieldType }, index) => {
          const renderItem = components[fieldType];

          return (
            <Div key={`${name}_${index}`} flex="1 1 40%" mt={[-2, -2, 0, 0]}>
              {renderItem?.({
                name,
                value,
                label,
                fieldType,
              })}
            </Div>
          );
        }
      )}
    </>
  );
};

const renderSelectorValue = ({ name, index, label, value, selectorField }) => (
  <Div key={`${name}_${index}`} flex="1 1 40%">
    <TextMediumWeight>
      {label}
      {`: `}
      <Text>{value?.[selectorField]}</Text>
    </TextMediumWeight>
  </Div>
);

const renderEditor = ({ name, index, label, value, fullWidth }) => {
  return (
    <Div key={`${name}_${index}`} flex={`1 1 ${fullWidth ? "100%" : "40%"}`}>
      {label && (
        <TextMediumWeight>
          {label}
          {`: `}
        </TextMediumWeight>
      )}
      <Div mt={2} />
      <StyledDescription dangerouslySetInnerHTML={{ __html: value }} />
    </Div>
  );
};

const renderMultiselect = ({ name, index, label, value, options }) => {
  const selectedOptions = options.filter(
    option => Array.isArray(value) && value.includes(option.value)
  );
  return (
    <Div key={`${name}_${index}`} flex="1 1 40%">
      <TextMediumWeight display="flex">
        {label}
        {`: `}
        <Text ml={2}>
          <ul>
            {selectedOptions.map(option => (
              <li key={option.value}>{option.label}</li>
            ))}
          </ul>
        </Text>
      </TextMediumWeight>
    </Div>
  );
};

const renderChip = ({ key, index, label, options }) => {
  return (
    <Div key={`${key}_${index}`} flex="1 1 40%">
      <TextMediumWeight>
        {label}
        {`: `}
      </TextMediumWeight>
      <Div
        display={"flex"}
        flexDirection={["column", "column", "row", "row"]}
        gridGap={3}
        mt={3}
      >
        {options?.map((option, optionIndex) => (
          <Chip
            key={`${key}_${index}_${optionIndex}`}
            label={option.label}
            icon={"icon-pin"}
            removeIcon={"icon-download-assignment"}
            onClick={() => {
              window.open(option?.url);
            }}
          />
        ))}
      </Div>
    </Div>
  );
};

const renderHtml = ({ key, index, label, value, fullWidth }) => {
  return (
    <Div
      key={`${key}_${index}`}
      flex={`1 1 ${fullWidth ? "100%" : "40%"}`}
      alignItems="center"
      gridGap={1}
      flexDirection="column"
    >
      <TextMediumWeight>
        {label}
        {`: `}
      </TextMediumWeight>
      <Div mt={2} />
      <StyledDescription dangerouslySetInnerHTML={{ __html: value }} />
    </Div>
  );
};

const renderDocumentChip = (value, e) => {
  const rowData = value[e.field];
  return (
    <Chip
      key={rowData?.id}
      label={rowData?.file_name}
      icon={<i className="icon-pin"></i>}
      removeIcon={"icon-download-assignment"}
      onClick={() => {
        window.open(rowData?.url);
      }}
    />
  );
};

const renderMultifiles = ({ key, index, value, messages }) => {
  return (
    <Div key={`${key}_${index}`} flex="1 1 40%">
      <Div
        display={"flex"}
        flexDirection={["column", "column", "row", "row"]}
        gridGap={3}
        mt={-3}
      >
        <StyledDataTable
          tableStyle={{ minWidth: "50rem" }}
          value={value}
          emptyMessage={messages.label_no_document_uploaded}
        >
          <Column
            field="file"
            header={messages.admin_customer_document_card_documents}
            body={renderDocumentChip}
          />
          <Column
            field="document_description"
            header={messages.admin_customer_document_card_document_description}
          ></Column>
          <Column
            field="created_at"
            header={messages.admin_customer_document_card_created_at}
          ></Column>
        </StyledDataTable>
      </Div>
    </Div>
  );
};

const components = {
  [FORM_INPUT_TYPE.FILLER]: renderFiller,
  [FORM_INPUT_TYPE.TEXT]: renderText,
  [FORM_INPUT_TYPE.DROPDOWN]: renderDropdown,
  [FORM_INPUT_TYPE.AUTOCOMPLETE]: renderSelectorValue,
  [FORM_INPUT_TYPE.CALENDAR]: renderCalendar,
  [FORM_INPUT_TYPE.EDITOR]: renderEditor,
  [FORM_INPUT_TYPE.MULTISELECT]: renderMultiselect,
  [FORM_INPUT_TYPE.FILES]: renderChip,
  [FORM_INPUT_TYPE.HTML]: renderHtml,
  [FORM_INPUT_TYPE.TEXTAREA]: renderHtml,
  [FORM_INPUT_TYPE.MULTI_FILES]: renderMultifiles,
};

const JsonFormCard = ({
  title,
  config,
  isEditPermission,
  onSubmit,
  isPaddingIncreased,
  formLayout,
}) => {
  const { messages } = useIntl();
  const initialValues = config.reduce((acc, obj) => {
    acc[obj.name] = obj.value;
    return acc;
  }, {});

  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);

  const handleEdit = () => {
    setIsEditDialogVisible(true);
  };

  const handleCloseDialog = () => {
    setIsEditDialogVisible(false);
  };
  return (
    <Card my={3} p={isPaddingIncreased ? 4 : 3} borderRadius="20px" width={1}>
      {isEditDialogVisible && (
        <JsonFormDialog
          title={title}
          config={config}
          onHide={() => handleCloseDialog()}
          onSubmit={onSubmit}
          initialValues={initialValues}
          formLayout={formLayout}
        />
      )}

      <Div
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <TextSemiBoldWeight>{title}</TextSemiBoldWeight>
        {isEditPermission && (
          <ButtonLinkIcon
            px={0}
            label={<TextMediumWeight>{messages.label_edit}</TextMediumWeight>}
            onClick={() => handleEdit()}
            icon={
              <Icon
                name="pen"
                mr={2}
                color="var(--turquoise)"
                fontSize="var(--fs-icon-s)"
              />
            }
          />
        )}
      </Div>

      <Div width={1} height="1px" backgroundColor="var(--grey-lightest)" />

      <Div
        mt={3}
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
        gridGap={3}
      >
        {config?.map(
          (
            {
              name,
              label,
              value,
              fieldType,
              options,
              optionFields,
              isAdminField,
              selectorField,
              fullWidth,
              isHidden,
            },
            index
          ) => {
            if (isAdminField && !isAdminUser()) {
              return null;
            }
            const renderItem = components[fieldType];
            return renderItem?.({
              name,
              index,
              label,
              value,
              options,
              optionFields,
              messages,
              selectorField,
              fullWidth,
              isHidden,
            });
          }
        )}
      </Div>
    </Card>
  );
};

JsonFormCard.propTypes = {
  title: PropTypes.string,
  config: PropTypes.array,
  isEditPermission: PropTypes.bool,
  onSubmit: PropTypes.func,
  isPaddingIncreased: PropTypes.bool,
  formLayout: PropTypes.object,
};

export default JsonFormCard;
