import React from "react";
import Div from "@components/Div";
import { useIntl } from "react-intl";
import { Text, TextSemiBoldWeight } from "@components/Text";
import PropTypes from "prop-types";
import { CloseButton } from "@components/Button";
import { addHyphenToNumber } from "@utils/utils";

const Header = ({
  name = "",
  street = "",
  zipcode = "",
  city = "",
  ssn = "",
  onRemoveObject,
  index = -1,
}) => {
  const { messages } = useIntl();
  const NoAddress = street === null || city === null || zipcode === null;

  return (
    <Div p={0} position="relative" m={0}>
      <CloseButton
        display={["none", "none", "block", "block"]}
        icon="pi pi-times"
        severity="secondary"
        onClick={onRemoveObject.bind(this, index)}
        rounded
        text
        position={"absolute"}
        top={-35}
        right={[0, 0, 0, -10]}
        alignItems={"center"}
        justifyContent={"center"}
        color="var(--grey) !important"
      />

      <Div
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        alignItems={["stretch", "stretch", "center", "center"]}
        justifyContent="flex-start"
        mt={1}
        gridGap={[3, 3, 0, 0]}
      >
        <Div
          display="flex"
          flexDirection={["row", "row", "column", "column"]}
          justifyContent="space-between"
          alignItems="start"
        >
          <Text>{messages.title_object_name}</Text>
          <Div display="flex" alignItems="center" maxWidth={["150px", "100%"]}>
            <TextSemiBoldWeight
              fontSize={[
                "var(--fs-text-m) !important",
                "var(--fs-text-m) !important",
                "var(--fs-tex)",
                "var(--fs-tex)",
              ]}
              mt={[0, 0, "10px", "10px"]}
            >
              {name}
            </TextSemiBoldWeight>
          </Div>
        </Div>

        <Div
          ml={[0, 0, "auto", "auto"]}
          display="flex"
          flexDirection={["row", "row", "column", "column"]}
          justifyContent="space-between"
          alignItems="start"
        >
          <Text>{messages.label_personal_number}</Text>
          <TextSemiBoldWeight
            fontSize={[
              "var(--fs-text-m) !important",
              "var(--fs-text-m) !important",
              "var(--fs-tex)",
              "var(--fs-tex)",
            ]}
            mt={[0, 0, "10px", "10px"]}
          >
            {addHyphenToNumber(ssn, 8)}
          </TextSemiBoldWeight>
        </Div>

        {!NoAddress && (
          <Div
            ml={[0, 0, 6, 6]}
            display="flex"
            flexDirection={["row", "row", "column", "column"]}
            justifyContent={"space-between"}
            alignItems="start"
          >
            <Text flex={3}>{messages.search_label_address}</Text>

            <TextSemiBoldWeight
              display={["none", "none", "block", "block"]}
              mt={"10px"}
              fontSize={[
                "var(--fs-text-m) !important",
                "var(--fs-text-m) !important",
                "var(--fs-tex)",
                "var(--fs-tex)",
              ]}
            >
              {`${street}, ${zipcode.replace(/(?=\d{2}$)/, " ")} ${city}`}
            </TextSemiBoldWeight>

            <Div
              display={["flex", "flex", "none", "none"]}
              flexDirection="column"
            >
              <TextSemiBoldWeight
                fontSize={[
                  "var(--fs-text-m) !important",
                  "var(--fs-text-m) !important",
                  "var(--fs-tex)",
                  "var(--fs-tex)",
                ]}
              >
                {street}
              </TextSemiBoldWeight>
              <TextSemiBoldWeight
                fontSize={[
                  "var(--fs-text-m) !important",
                  "var(--fs-text-m) !important",
                  "var(--fs-tex)",
                  "var(--fs-tex)",
                ]}
              >{`${zipcode.replace(
                /(?=\d{2}$)/,
                " "
              )} ${city}`}</TextSemiBoldWeight>
            </Div>
          </Div>
        )}
      </Div>
    </Div>
  );
};

Header.propTypes = {
  name: PropTypes.string,
  street: PropTypes.string,
  zipcode: PropTypes.string,
  city: PropTypes.string,
  ssn: PropTypes.string,
  onRemoveObject: PropTypes.func,
  index: PropTypes.string,
};

export default Header;
