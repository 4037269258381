import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { personInformationActions } from "@app/reducers/personInformationReducer";
import getSubscription from "@app/services/subscription/getSubscription";

import { useUser } from "@contexts/AuthProvider";

import BankIDLogin from "@components/BankidComponent/BankIDLogin";
import Card from "@components/Card";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import GDPRInformationMessage from "@components/privacyPolicies/GDPRInformationMessage";
import Section from "@components/Section";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import {
  ROUTES,
  ADMIN_NAV,
  ADMIN_NAV_MY_PROFILE,
  COMPANY,
  CUSTOMER,
} from "@utils/constant";
import { BANK_ID_SCOPES } from "@utils/enum";
import { isAdminUser, isAuthenticated } from "@utils/utils";

import { LoginCredentialsForm, LoginOptionsSeparator } from "./components";

const isTokenValid = token => {
  return token !== null;
};

export const Login = ({ isAdmin }) => {
  const { hasAnyPermissions } = useAuthorization();
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { userInfo: { type: userType, customer = {} } = {} } = useSelector(
    state => state.authReducer
  );
  const { showErrorToast } = useToast();
  const { initializeUser } = useUser();

  const findPath = () => {
    const [{ redirectTo: fallbackUrl }] = ADMIN_NAV_MY_PROFILE;
    const { redirectTo: targetRedirectUrl } =
      ADMIN_NAV.find(({ permissions = [] }) =>
        hasAnyPermissions(permissions)
      ) ?? {};

    return targetRedirectUrl ?? fallbackUrl;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const redirectToPage = async user => {
    const { customer: { id = "" } = {}, type = "" } = user;

    if (type == CUSTOMER && id) {
      const { data: { data: subscriptionData = [] } = {} } =
        await getSubscription(id);
      const [activeSubscription = false] = subscriptionData;

      dispatch(
        personInformationActions.setHasActiveSubscription(activeSubscription)
      );
    }
  };

  const handleError = useCallback(
    error => {
      showErrorToast(error ?? messages.error_no_users_found_with_ssn);
    },
    [messages.error_no_users_found_with_ssn, showErrorToast]
  );

  const handleLoginSubmit = ({ token, user }) => {
    if (!isTokenValid(token)) {
      handleError(messages.invalid_data);

      return;
    }

    localStorage.setItem("token", token);

    initializeUser();

    redirectToPage(user);
  };

  if (isAuthenticated()) {
    const redirectTarget = { pathname: ROUTES.USER_PROFILE.URL };

    if (isAdminUser()) {
      redirectTarget.pathname = findPath();
    } else if (userType === CUSTOMER && customer.type === COMPANY) {
      redirectTarget.pathname = ROUTES.SEARCH.URL;
    }

    return <Redirect to={redirectTarget} />;
  }

  return (
    <Section
      flex={1}
      display="flex"
      justifyContent="center"
      bg="var(--site-background-color)"
    >
      <Div
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Div
          display="flex"
          width={1}
          alignItems="center"
          justifyContent="center"
        >
          <Card width={1} maxWidth="464px">
            <Div
              px={[3, 3, 4, 4]}
              py="24px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gridGap={3}
            >
              <H2 color="var(--blue-dark) !important">{messages.login}</H2>

              <LoginCredentialsForm
                onSubmit={handleLoginSubmit}
                onError={handleError}
              />

              {/* MARK: hide customer part of BankID for 4.2 release */}
              {isAdmin && (
                <>
                  <LoginOptionsSeparator />

                  <H2 color="var(--blue-dark) !important">
                    {messages.title_bankid_login}
                  </H2>

                  <Div width={1} maxWidth="200px">
                    <BankIDLogin
                      scope={BANK_ID_SCOPES.LOGIN}
                      buttonLabel={messages.login}
                      onSuccess={handleLoginSubmit}
                      onError={handleError}
                    />
                  </Div>
                </>
              )}
            </Div>
          </Card>
        </Div>

        <GDPRInformationMessage />
      </Div>
    </Section>
  );
};

Login.propTypes = {
  isAdmin: PropTypes.bool,
};

export default Login;
