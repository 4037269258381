import React from "react";
import hash from "object-hash";

import Div from "@components/Div";
import { H2 } from "@components/Heading";
import RichText from "@components/RichText";
import { Text, TextLarge } from "@components/Text";

import {
  Accordion,
  Card,
  CoResidentDiv,
  DeviationsPanel,
  Division,
} from "../components";
import { default as DeviationsAccordion } from "../components/Deviations/CaseDeviationsAccordion";
import { REPORT_ELEMENTS_TYPE } from "../types";

const parseBaClassName = (baClassName = "") => {
  return baClassName?.replace?.(/,/g, " ") ?? "";
};

const renderPanel = (
  { elements, title: elementTitle, baClassName, state, isPDF },
  data
) => {
  const isCardComponent = baClassName?.includes("card");

  if (isCardComponent) {
    return renderCard({ title: elementTitle, elements, baClassName }, data);
  }

  const isSubPanel = baClassName?.includes("sub-panel");

  if (isSubPanel) {
    return renderSubPanel({ title: elementTitle, elements, baClassName }, data);
  }

  const isToggleableComponent =
    baClassName?.includes("toggleable") || state === "collapsed";

  if (isToggleableComponent) {
    return renderToggleable({ title: elementTitle, elements, state, isPDF }, data);
  }

  const nestedElements = elements ?? [];
  const title = elementTitle ?? "";
  const className = parseBaClassName(baClassName);

  return (
    <Division
      className={className}
      display="flex"
      flexDirection="column"
      gridGap={3}
    >
      {title && <H2 color="var(--blue-dark) !important">{title}</H2>}
      {nestedElements
        .filter(({ isHidden }) => !isHidden)
        .map(element => {
          const elementToRender = ELEMENTS_BY_TYPE[element.type];

          return elementToRender?.(
            { ...element, key: `${element.name}_${hash(element)}`, isPDF },
            data
          );
        })}
    </Division>
  );
};

const renderComment = ({ name, defaultValue, baClassName, isHTML }, data) => {
  const value = data?.[name] ?? defaultValue;
  const className =
    name === "deviations_paragraph1"
      ? "deviations-footer"
      : parseBaClassName(baClassName);

  return isHTML ? (
    renderHtml({ baClassName: className, html: value })
  ) : (
    <TextLarge fontSize="var(--fs-text-m) !important" className={className}>
      {value}
    </TextLarge>
  );
};

const renderGrade = ({ name, defaultValue }, data) => {
  const value = data?.[name] ?? defaultValue;

  const color = data?.[`grade_color`] ?? "";
  const bgColor = data?.[`grade_bg_color`] ?? "";

  return (
    <Div
      bg={`var(--${bgColor})`}
      borderRadius="10px"
      padding={["12px 24px", "12px 24px", "22px 50px", "22px 50px"]}
      alignItems="center"
    >
      <Text
        color={`var(--${color}) !important`}
        fontWeight="var(--semibold-weight) !important"
        fontSize={[
          "var(--fs-text-small) !important",
          "var(--fs-text-small) !important",
          "var(--fs-text) !important",
        ]}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </Div>
  );
};

const renderCoresident = ({ name, title, defaultValue, baClassName }, data) => {
  const value = data?.[name] ?? defaultValue;
  const updatedValue = value?.replace(
    /(<a[^>]*>)(\d{10}|\d{12})(<\/a>)/g,
    (match, p1, p2, p3) => p1 + (p2.slice(0, -4) + "-" + p2.slice(-4) + p3)
  );
  const className = parseBaClassName(baClassName);
  return (
    <CoResidentDiv className={className} display="flex" gridGap={1}>
      <Text className="text-title">{title && `${title}: `}</Text>
      <Text
        className="text-value"
        dangerouslySetInnerHTML={{ __html: updatedValue }}
      />
    </CoResidentDiv>
  );
};

const renderText = ({ name, title, defaultValue, baClassName }, data) => {
  if (baClassName?.includes("grade")) {
    return renderGrade({ name, title, defaultValue, baClassName }, data);
  }

  if (baClassName?.includes("coresident")) {
    return renderCoresident({ name, title, defaultValue, baClassName }, data);
  }

  const value = data?.[name] ?? defaultValue;
  const className = parseBaClassName(baClassName);
  return (
    <Div className={className} display="flex" alignItems="center" gridGap={1}>
      <Text className="text-title">{title && `${title}: `}</Text>
      <Text
        className="text-value"
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </Div>
  );
};

const renderPaneldynamic = (props, data) => {
  const { name, baClassName, isPDF = false } = props;

  // eslint-disable-next-line no-console
  // console.log("renderPaneldynamic", props, isPDF);

  const className = parseBaClassName(baClassName);
  const deviations = data?.[name] ?? [];
  const formattedDeviations = deviations
    .filter(item => Object.values(item).length > 0)
    .map(
      ({
        deviation_title: title,
        deviation_grade: grade,
        deviation_details: htmlContent,
        deviation_color: iconColor,
      }) => ({
        title,
        grade,
        htmlContent,
        iconColor,
      })
    );

  return (
    <DeviationsPanel
      className={className}
      deviations={formattedDeviations}
      isPDF={isPDF}
    />
  );
};

const renderCard = ({ title, elements = [], baClassName }, data) => {
  const className = parseBaClassName(baClassName);
  const isTwoColumnsStyle = className.includes("column-2");

  return (
    <Card title={title}>
      <Div
        className={className}
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
        gridGap={3}
      >
        {elements
          .filter(({ isHidden }) => !isHidden)
          .map((element, index) => {
            const _hash = hash({ name: element.name, index, data });
            const key = `${element.name}_${index}_${_hash}`;
            const elementToRender = ELEMENTS_BY_TYPE[element.type];

            return (
              <Div
                key={`card_${key}`}
                flex={`1 1 ${isTwoColumnsStyle ? "40%" : "100%"}`}
                alignItems="center"
              >
                {elementToRender?.({ ...element, key: key }, data)}
              </Div>
            );
          })}
      </Div>
    </Card>
  );
};

const renderToggleable = ({ title, elements = [], state, isPDF = false }, data) => (
  <Accordion title={title} state={state} isPDF={isPDF}>
    {elements
      .filter(({ isHidden }) => !isHidden)
      .map(element => {
        const elementToRender = ELEMENTS_BY_TYPE[element.type];

        return elementToRender?.({ ...element }, data);
      })}
  </Accordion>
);

const renderHtml = ({ name, baClassName, html }, data) => (
  <RichText className={baClassName}>{html ?? data?.[name]}</RichText>
);

const renderRichContentEditor = ({ name, baClassName, html }, data) => {
  const className = ["rich-content-editor", baClassName]
    .filter(Boolean)
    .join(" ");

  return <RichText className={className}>{html ?? data?.[name]}</RichText>;
};

const renderSubPanel = ({ title, elements, baClassName }, data) => {
  const content = elements
    .filter(({ isHidden, visible }) => isHidden !== true && visible !== false)
    .map(element => {
      const elementToRender = ELEMENTS_BY_TYPE[element.type];

      return elementToRender?.({ ...element }, data);
    });
  const isExpanded = baClassName?.includes?.("expanded");

  return (
    <DeviationsAccordion
      content={[{ title, grade: data?.deviation_grade, content }]}
      activeIndex={[isExpanded ? 0 : -1]}
    />
  );
};

export const ELEMENTS_BY_TYPE = {
  [REPORT_ELEMENTS_TYPE.PANEL]: renderPanel,
  [REPORT_ELEMENTS_TYPE.COMMENT]: renderComment,
  [REPORT_ELEMENTS_TYPE.TEXT]: renderText,
  [REPORT_ELEMENTS_TYPE.PANELDYNAMIC]: renderPaneldynamic,
  [REPORT_ELEMENTS_TYPE.CARD]: renderCard,
  [REPORT_ELEMENTS_TYPE.TOGGLEABLE]: renderToggleable,
  [REPORT_ELEMENTS_TYPE.HTML]: renderHtml,
  [REPORT_ELEMENTS_TYPE.RICH_CONTENT_EDITOR]: renderRichContentEditor,
};
